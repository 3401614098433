import React from 'react';

const events = ['mousedown', 'touchstart'];

export const useClickOutsideListener = (ref: any) => {

    const [active, setActive] = React.useState(false);

    React.useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setActive(false);
            }
        }

        events.forEach(eventName => document.addEventListener(eventName, handleClickOutside))

        return () => {
            events.forEach(eventName => document.removeEventListener(eventName, handleClickOutside))
        };
    }, [ref]);

    const toggle = (e: any) => {
        e.stopPropagation();
        setActive(!active);
    };

    return { active, setActive, toggle };
}
