import React from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from 'reactstrap';
import { IDeck, ITemplateFile } from '../../interfaces/business';
import { templateActions } from '../../store';
import { IRootState } from '../../store/store';
import { getDefaultDecksRequiringFile, getOptionalDecksRequiringFile } from '../../utils/templateFileUtils';

interface IProps {
    isOpen: boolean,
    templateFile: ITemplateFile,
    decks: IDeck[],
    children?: never;
    onClose: () => void,
    deleteTemplateFile: (templateFileId: number) => void;
}

interface IState {

}

const initialState: IState = {};

class DeleteTemplateFileModal extends React.PureComponent<IProps, IState> {

    state: IState = initialState;

    closeModal = () => {
        this.setState({ ...initialState });
        this.props.onClose();
    };

    onDeleteSelectedFile = () => {
        this.props.deleteTemplateFile(this.props.templateFile.id);
        this.closeModal();
    };

    render() {
        const defaultDecks = getDefaultDecksRequiringFile(this.props.templateFile, this.props.decks);
        const optionalDecks = getOptionalDecksRequiringFile(this.props.templateFile, this.props.decks);

        return <Modal isOpen={this.props.isOpen} size='sm' id='modalDeleteTemplateFile' toggle={this.closeModal}>
            <ModalHeader toggle={this.closeModal}/>
            <ModalBody>
                <h2>Confirmation</h2>
                {defaultDecks.length > 0 &&
                <Alert color='danger'>By deleting this template file, you will break following decks in default
                    language {this.props.templateFile.language}: {defaultDecks.map(deck => deck.externalId).join(', ')}.</Alert>}
                {optionalDecks.length > 0 &&
                <Alert color='warning'>By deleting this template file, you will break following decks in
                    language {this.props.templateFile.language}: {optionalDecks.map(deck => deck.externalId).join(', ')}.</Alert>}
                {!optionalDecks.length && !defaultDecks.length &&
                <p>No decks will be broken by deletion of this file.</p>}
                <p>Do you really want to delete it?</p>
            </ModalBody>
            <ModalFooter>
                <ButtonGroup>
                    <Button color='primary' onClick={this.onDeleteSelectedFile}>Delete</Button>
                    <Button color='secondary' onClick={this.closeModal}>Cancel</Button>
                </ButtonGroup>
            </ModalFooter>
        </Modal>
    }
}

const mapStateToProps = (state: IRootState) => ({
    decks: state.template.decks,
});
const actions = {
    deleteTemplateFile: templateActions.deleteFile.request,
};

export default connect(
    mapStateToProps,
    actions,
)(DeleteTemplateFileModal);
