import React from 'react';
import classNames from 'classnames';

interface IProps {
    value?: File;
    label?: string;
    accept: string;
    multiple?: boolean;
    disabled?: boolean;
    className?: string;
    children?: never;
    onChange: (files: FileList) => void;
}

const FileInput: React.FC<IProps> = (props: IProps) => {

    const onChange = (event: any) => {
        if (event.target.files.length) {
            props.onChange(event.target.files);
        }
    };

    return <div className='form-group'>
        {props.label && <label className='form-label'>{props.label}</label>}
        <div className={classNames('input-group', props.className)}>
            <input type='file'
                   name='templateFileInput'
                   disabled={props.disabled}
                   id='templateFileInput'
                   className='form-control'
                   accept={props.accept}
                   multiple={props.multiple}
                   onChange={onChange}/>
        </div>
    </div>;
}

export default FileInput;
