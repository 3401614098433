import * as React from 'react';
import { v4 as uuid } from 'uuid';
import * as Popper from '@popperjs/core';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { UncontrolledTooltip } from 'reactstrap';

interface IProps {
    text: any;
    forId?: string;
    front?: boolean;
    placement?: Popper.Placement;
    children?: never;
}

export const Tooltip: React.FC<IProps> = (props: IProps) => {
    const id = props.forId ? props.forId : `tooltip${uuid().substr(0, 8)}`;
    const wrapperClasses = classnames('bjb-tooltip', {'bjb-tooltip--front': props.front});

    // fix for IE: tooltip not disappearing quickly enough causing weird sticky effect
    const delay = { show: 100, hide: 0 };

    const getTooltipJsx = () => <UncontrolledTooltip target={id} delay={delay} placement={props.placement} >
        <ReactMarkdown source={props.text}/>
    </UncontrolledTooltip>;

    return (<>
            {props.forId && getTooltipJsx()}

            {!props.forId &&
            <span className={wrapperClasses}>
            <i className="icon icon-info-b-light icon--tooltip icon-inline clickable" id={id}/>
                {getTooltipJsx()}
            </span>}
        </>
    );
};

export default Tooltip;
