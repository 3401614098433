import { getType } from 'typesafe-actions';
import * as actions from './user-actions';
import { IRootState } from '../store';
import { IUser, UserRole } from '../../interfaces/user';

export interface IUserState extends IUser {
    lastLoginFailed: boolean;
}

export const initialState: IUserState = {
    username: null,
    roles: [],
    lastLoginFailed: false,
};

export default function userReducer(state: IUserState = initialState, action: any): IUserState {
    switch (action.type) {
        case getType(actions.detectUser.success):
            const user: IUser = action.payload;
            return { ...state, ...user };
        case getType(actions.login.success):
            return { ...state, lastLoginFailed: false };
        case getType(actions.login.failure):
            return { ...state, lastLoginFailed: true };
        default:
            return state;
    }
}

interface IUserSelectors {
    username: (state: IRootState) => string,
    isAdmin: (state: IRootState) => boolean,
    isLoggedIn: (state: IRootState) => boolean,
}

export const userSelectors: IUserSelectors = {
    username: (state: IRootState) => state.user.username,
    isAdmin: (state: IRootState) => state.user.roles.includes(UserRole.ADMIN),
    isLoggedIn: (state) => !!state.user.username,
};
