import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { IDeck } from '../../interfaces/business';
import { IRootState } from '../../store/store';
import { getLoadingListJsx } from '../../utils/renderUtils';
import { Tooltip } from '../atoms';
import { DeckModal } from '../modals';

const emptyDeck: IDeck = {
    externalId: '',
    name: '',
    description: '',
    defaultLanguage: '',
    templates: [],
    requiredBCs: []
};

interface IProps {
    decks: IDeck[];
    children?: never;
}

interface IState {
    currentDeck: IDeck;
}

export class Decks extends React.PureComponent<IProps, IState> {

    state: IState = { currentDeck: undefined };

    onEditDeck = (currentDeck: IDeck) => this.setState({ currentDeck });
    onCreateDeck = () => this.setState({ currentDeck: emptyDeck });
    onCloseModal = () => this.setState({ currentDeck: undefined });

    getDecksJsx = () => {
        const showInvalidBcCol = this.props.decks.find(deck => deck.invalidBCs && deck.invalidBCs.length > 0);
        const showInvalidRMLocationCol = this.props.decks.find(deck => deck.invalidRMLocations && deck.invalidRMLocations.length > 0);
        return <Table>
            <thead>
            <tr>
                <th>External ID</th>
                <th>Name</th>
                <th>Description</th>
                <th>Default language</th>
                <th>Booking centers</th>
                <th>RM locations</th>
                {showInvalidBcCol && <th>
                    Invalid BC
                    <Tooltip text='Booking centers, for which template files in the default language are missing'/>
                </th>}
                {showInvalidBcCol && <th>
                    Invalid RM location
                    <Tooltip text='RM locations, for which template files in the default language are missing'/>
                </th>}
                <th/>
            </tr>
            </thead>
            <tbody>
            {this.props.decks.map(deck => {
                const onEditClick = () => this.onEditDeck(deck);
                return <tr key={deck.id}>
                    <td>{deck.externalId}</td>
                    <td>{deck.name}</td>
                    <td>{deck.description}</td>
                    <td>{deck.defaultLanguage.toUpperCase()}</td>
                    <td>{(deck.requiredBCs || []).join(', ')}</td>
                    <td>{(deck.requiredRMLocations.map(rml => rml === 'WO'? 'World': rml) || []).join(', ')}</td>
                    {showInvalidBcCol && <td><span className='error-message'>{deck.invalidBCs.join(', ')}</span></td>}
                    {showInvalidRMLocationCol && <td><span className='error-message'>{deck.invalidRMLocations.join(', ')}</span></td>}
                    <td><i className='icon icon-edit-primary clickable' onClick={onEditClick}/></td>
                </tr>;
            })}
            </tbody>
        </Table>;
    };

    render() {
        return <Container>
            <Row>
                <Col sm={12}>
                    <h2 className='admin-header pt-5'>View contract decks</h2>
                    {getLoadingListJsx(this.props.decks, 'No decks configured yet.', 'Failed to load decks.', this.getDecksJsx)}
                    <Button color='primary' className='mt-3' onClick={this.onCreateDeck}>Create deck</Button>
                </Col>
            </Row>
            {this.state.currentDeck && <DeckModal deck={this.state.currentDeck}
                                                  onClose={this.onCloseModal}
                                                  isOpen={!!this.state.currentDeck}/>}
        </Container>;
    }
}

const mapStateToProps = (state: IRootState) => ({
    decks: state.template.decks,
});

export default connect(
    mapStateToProps,
    null,
)(Decks);
