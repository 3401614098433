import React from 'react';
import classnames from 'classnames';
import { FormFeedback, Label } from 'reactstrap';
import Tooltip from './Tooltip';

interface IProps {
    name: string;
    label?: string;
    tooltip?: string;
    className?: string;
    required?: boolean;
    invalidMessage?: string
    children: React.ReactNode;
}

const CheckboxGroup: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => (
    <div className={classnames('form-group', props.className, props.invalidMessage ? 'form-invalid' : '')}>
        <Label for={props.name} className="custom-control-group-title">{props.label}
            {props.tooltip && <Tooltip text={props.tooltip} key={props.name}/>}
        </Label>
        <div> {props.children || <span className={props.required ? 'error-message' : ''}>(none available)</span>}</div>
        <FormFeedback invalid='true'>{props.invalidMessage}</FormFeedback>
    </div>
);

export default CheckboxGroup;
