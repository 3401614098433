import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './app/api/interceptor';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { history, store } from './app/store/store';
import App from './app/container/App';
import './styles/main.scss';

const ConApp = () => (
    <Provider store={store}>
        <Router history={history}>
            <App/>
        </Router>
    </Provider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<ConApp/>);
