import React from 'react';
import { connect } from 'react-redux';
import { Location } from 'history';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AuthWrapper from '../components/AuthWrapper';
import { JbHeader } from '../components/toolbar/index';
import { IRootState } from '../store/store';
import * as route from '../config/routes';
import { userSelectors } from '../store';
import { Dashboard, ErrorScreen, NoAccessScreen, TimeoutScreen } from './index';
import { ToastCloseButton } from '../components/atoms';
import HistoryListener from '../components/HistoryListener';

interface IProps {
    location: Location;
    isLoggedIn: boolean;
}

export class App extends React.PureComponent<IProps> {

    render() {
        return <AuthWrapper>
            <div className="app">
                <HistoryListener/>
                <JbHeader/>
                <Routes>
                    <Route path={route.error} Component={ErrorScreen} key='ErrorScreen'/>,
                    <Route path={route.noAccess} Component={NoAccessScreen} key='NoAccessScreen'/>,
                    <Route path={route.timeout} Component={TimeoutScreen} key='TimeoutScreen'/>,
                    <Route path={`${route.dashboard}/*`} Component={Dashboard} key='Dashboard'/>,
                </Routes>
                <ToastContainer hideProgressBar={true} closeButton={ToastCloseButton}/>
            </div>
        </AuthWrapper>;
    }
}

const mapStateToProps = (state: IRootState) => ({
    location: state.router.location,
    isLoggedIn: userSelectors.isLoggedIn(state),
});


export default connect(
    mapStateToProps,
    null,
)(App);

