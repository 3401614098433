import React from 'react';
import { useLocation } from 'react-router-dom';

const HistoryListener: React.FC = () => {
    const location = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return null;
};

export default HistoryListener;
