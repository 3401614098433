import React from 'react';
import { LoadingIndicatorElement } from '../components/atoms';

export const getLoadingListJsx = (listProp: any[], emptyMessage: string, errorMessage: string, renderFn: () => any ) => {
    if (listProp === undefined) {
        return <LoadingIndicatorElement small={true}/>
    }
    if (listProp === null) {
        return <p className='error-message'>{errorMessage}</p>
    }
    if (listProp.length === 0) {
        return <p>{emptyMessage}</p>
    }
    return renderFn();
};
