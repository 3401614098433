import { getType } from 'typesafe-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { templateActions, userActions } from '../store';
import { IDeck, ITemplate, ITemplateFile } from '../interfaces/business';
import templateApi from '../api/template-api';
import deckApi from '../api/deck-api';

export function* getData() {
    yield put(templateActions.loadTemplates.request());
    yield put(templateActions.loadTemplateFiles.request());
    yield put(templateActions.loadDecks.request());
}

export function* getTemplates() {
    try {
        const templates: ITemplate[] = yield call([templateApi, templateApi.getTemplateList]);
        yield put(templateActions.loadTemplates.success(templates));
    } catch (error) {
        yield put(templateActions.loadTemplates.failure(error));
    }
}

export function* getTemplateFiles() {
    try {
        const templateFiles: ITemplateFile[] = yield call([templateApi, templateApi.getTemplateFileList]);
        yield put(templateActions.loadTemplateFiles.success(templateFiles));
    } catch (error) {
        yield put(templateActions.loadTemplateFiles.failure(error));
    }
}

export function* deleteTemplateFile(action: any) {
    try {
        yield call([templateApi, templateApi.deleteTemplateFile], action.payload);
        yield put(templateActions.deleteFile.success());
        yield put(templateActions.loadAllData());
    } catch (error) {
        yield put(templateActions.deleteFile.failure(error));
    }
}

export function* getDecks() {
    try {
        const decks: IDeck[] = yield call([deckApi, deckApi.getDeckList]);
        yield put(templateActions.loadDecks.success(decks));
    } catch (error) {
        yield put(templateActions.loadDecks.failure(error));
    }
}

export default function* templateSagas() {
    yield takeEvery(getType(userActions.detectUser.success), getData);
    yield takeEvery(getType(templateActions.loadAllData), getData);
    yield takeEvery(getType(templateActions.loadTemplates.request), getTemplates);
    yield takeEvery(getType(templateActions.loadTemplateFiles.request), getTemplateFiles);
    yield takeEvery(getType(templateActions.deleteFile.request), deleteTemplateFile);
    yield takeEvery(getType(templateActions.loadDecks.request), getDecks);
}
