import React, { useEffect, useState } from 'react';
import { Button, Input, Table } from 'reactstrap';
import { Accordion, TableFilter } from '../atoms';
import { getLoadingListJsx } from '../../utils/renderUtils';
import { IMap, ITemplate, ITemplateFile } from '../../interfaces/business';
import { ISelectOption } from './Select';

interface IProps {
    templates: ITemplate[];
    onCountriesChange: (template: ITemplate, countries: string) => void;
    isUpdateLoading: boolean;
    setIsUpdateLoading: (val: boolean) => void;
}

type CountriesMap = {
    [templateNumber: string]: string;
};

const getFileFilterOptions = (templates: ITemplate[], mapFn: (file: ITemplate) => string) =>
    [...new Set((templates || []).map(mapFn))].map((v) => ({ value: v, label: v }));

const SortableTemplateTable: React.FC<IProps> = (props: IProps) => {
    const [countriesMap, setCountriesMap] = useState<CountriesMap>({});
    const [availableFilters, setAvailableFilters] = useState<IMap<ISelectOption[]>>({
        templateNumber: [],
    });
    const [activeFilters, setActiveFilters] = useState<IMap<string[]>>({
        templateNumber: [],
    });

    useEffect(() => {
        if (props.templates) {
            const templateNumber = getFileFilterOptions(props.templates, (f) => f.templateNumber);
            setAvailableFilters({ templateNumber });
            setCountriesMap(
                props.templates.reduce(
                    (acc, template) => {
                        acc[template.templateNumber] = template.specificCountries.join(',');
                        return acc;
                    },
                    {} as CountriesMap,
                ),
            );
        }
    }, [props.templates]);

    const handleCountryChange = (templateNumber: string, value: string) => {
        setCountriesMap((prevState) => ({
            ...prevState,
            [templateNumber]: value,
        }));
    };

    const getVisibletemplates = () => {
        const visibletemplates: ITemplate[] = props.templates.filter((template) => {
            let matchesFilter = true;
            Object.keys(activeFilters).forEach((filePropName: keyof ITemplate) => {
                const propActiveFilters = activeFilters[filePropName];
                if (matchesFilter && propActiveFilters.length > 0) {
                    matchesFilter = matchesFilter && propActiveFilters.includes(template[filePropName] as string);
                }
            });
            return matchesFilter;
        });

        return visibletemplates.map((template, index) => {
            const countries = countriesMap && (template.templateNumber in countriesMap ? countriesMap[template.templateNumber] : '');
            const onUpdateClick = () => {
                props.setIsUpdateLoading(true);
                props.onCountriesChange(template, countries);
            };
            return (
                <tr key={`${index}_${template.templateNumber}`}>
                    <td>{template.templateNumber}</td>
                    <td>
                        <Input
                            id="specificCountries"
                            value={countries}
                            placeholder="CH,FR,DE"
                            onChange={(e) => handleCountryChange(template.templateNumber, e.target.value)}
                        />
                    </td>
                    <td>
                        <Button color="primary" disabled={!countries && template.specificCountries.length === 0} onClick={onUpdateClick}>
                            Update
                            {props.isUpdateLoading && <i className="icon icon--right icon-loading-white" />}
                        </Button>
                    </td>
                </tr>
            );
        });
    };

    const onFilterValueChange = (filePropName: string, value: string) => {
        const propActiveFilters = activeFilters[filePropName];
        const updatedValues = propActiveFilters.includes(value) ? propActiveFilters.filter((f) => f !== value) : [...propActiveFilters, value];
        setActiveFilters({ ...activeFilters, [filePropName]: updatedValues });
    };

    const getTableFilter = (filePropName: keyof ITemplateFile, label: string) => {
        const onFilterChange = (value: string) => onFilterValueChange(filePropName, value);
        return (
            <TableFilter
                id={`filter-${filePropName}`}
                label={label}
                values={activeFilters[filePropName]}
                options={availableFilters[filePropName]}
                onOptionSelected={onFilterChange}
            />
        );
    };

    const getContentJsx = () => (
        <Accordion title={`${props.templates.length} templates available`}>
            <Table className="table--sortable">
                <thead>
                    <tr>
                        <th>{getTableFilter('templateNumber', 'Template number')}</th>
                        <th>Specific Countries</th>
                    </tr>
                </thead>
                <tbody>{getVisibletemplates()}</tbody>
            </Table>
        </Accordion>
    );

    return getLoadingListJsx(props.templates, 'No templates available yet.', 'Failed to load templates.', getContentJsx);
};

export default SortableTemplateTable;
