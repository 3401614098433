import React from 'react';
import { connect } from 'react-redux';
import { User } from 'oidc-client-ts';
import { AuthProvider, UserManager } from 'oidc-react';
import { userActions, } from '../store';
import {
    REACT_APP_CON_AUTH_AUTHORITY,
    REACT_APP_CON_AUTH_CLIENT_ID,
    REACT_APP_CON_AUTH_RESOURCE,
} from '../config/constants';

interface IProps {
    children?: React.ReactNode;
    onUserSignedIn: (user: User) => void;
}

const currentHost = `${window.location.protocol}//${window.location.host}`;

const userManager = new UserManager({
    authority: REACT_APP_CON_AUTH_AUTHORITY,
    client_id: REACT_APP_CON_AUTH_CLIENT_ID,
    redirect_uri: `${currentHost}/dashboard`,
    response_type: 'code',
    scope: 'openid',
    automaticSilentRenew: true,
    silent_redirect_uri: `${currentHost}/silent_renew.html`,
    loadUserInfo: false,
    extraQueryParams: { resource: REACT_APP_CON_AUTH_RESOURCE },
});

const AuthWrapper: React.FC<IProps> = (props: IProps) => {

    const onSignIn = async (user: User) => props.onUserSignedIn(user);

    return <AuthProvider userManager={userManager} onSignIn={onSignIn}>
        {props.children}
    </AuthProvider>;
}

const actions = {
    onUserSignedIn: userActions.userSignedIn,
};

export default connect(
    null,
    actions,
)(AuthWrapper);
