import axios  from 'axios';
import { REACT_APP_CON_AUTH_SESSION_KEY } from '../config/constants';

axios.interceptors.request.use((config: any) => {
    // IE bugfix for caching ajax GET requests
    // see https://stackoverflow.com/questions/45912500/reactjs-ie11-not-making-new-http-request-using-cached-data
    // see https://thisinterestsme.com/ajax-request-internet-explorer-cache/
    const headers = { ...config.headers };
    headers['Cache-Control'] = 'no-cache';
    headers['Pragma'] = 'no-cache';
    headers['language'] = 'en';

    const storedUser = JSON.parse(sessionStorage.getItem(REACT_APP_CON_AUTH_SESSION_KEY));
    if (storedUser) {
        headers['Authorization'] = `Bearer ${storedUser.access_token}`;
    }
    return { ...config, headers };
});
