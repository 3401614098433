import { all } from 'redux-saga/effects';
import userSagas from './user-sagas';
import routingSagas from './routing-sagas';
import templateSagas from './template-sagas';
import toastSagas from './toast-sagas';

export default function* rootSaga() {
    yield all([
        userSagas(),
        templateSagas(),
        routingSagas(),
        toastSagas(),
    ]);
}
