import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { SmallContainer } from '../components/utils';

const NoAccessScreen: React.FC = () => <Container>
    <SmallContainer>
        <Row>
            <Col sm={12} className='confirmation'>
                <i className="icon icon-error icon--big"/>
                <h2>Sorry</h2>
                <p className='mb-3'>You have no access rights to view Contract Service application.</p>
            </Col>
        </Row>
    </SmallContainer>
</Container>;

export default NoAccessScreen;
