import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { IDeck, ITemplate, ITemplateFile } from '../../interfaces/business';

export const loadAllData = createStandardAction('template/load-all-data')<void>();

export const loadTemplates = createAsyncAction(
    'template/GET-TEMPLATES',
    'template/get-templates-success',
    'template/get-templates-ERROR',
)<void, ITemplate[], Error>();

export const loadTemplateFiles = createAsyncAction(
    'template/GET-TEMPLATE-FILES',
    'template/get-template-files-success',
    'template/get-template-files-ERROR',
)<void, ITemplateFile[], Error>();

export const deleteFile = createAsyncAction(
    'template/DELETE',
    'template/delete-success',
    'template/delete-ERROR',
)<number, void, Error>();

export const loadDecks = createAsyncAction(
    'template/GET-DECKS',
    'template/get-decks-success',
    'template/get-decks-ERROR',
)<void, IDeck[], Error>();

export const loadDeck = createAsyncAction(
    'template/GET-DECK',
    'template/get-deck-success',
    'template/get-deck-ERROR',
)<number, IDeck, Error>();

export const createDeck = createAsyncAction(
    'template/CREATE-DECK',
    'template/create-deck-success',
    'template/create-deck-ERROR',
)<IDeck, IDeck, Error>();

export const updateDeck = createAsyncAction(
    'template/UPDATE-DECK',
    'template/update-deck-success',
    'template/update-deck-ERROR',
)<IDeck, IDeck, Error>();
