import { getType } from 'typesafe-actions';
import { goBack, push, replace } from 'redux-first-history';
import { takeEvery } from 'redux-saga/effects';
import { routingActions } from '../store';
import { store } from '../store/store';

export function* goToPage(action: any) {
    yield store.dispatch(push(action.payload));
}

export function* replacePage(action: any) {
    yield store.dispatch(replace(action.payload));
}

export function* browserBack() {
    yield store.dispatch(goBack());
}

export default function* routingSagas() {
    yield takeEvery(getType(routingActions.goTo), goToPage);
    yield takeEvery(getType(routingActions.replace), replacePage);
    yield takeEvery(getType(routingActions.goBack), browserBack);
}
