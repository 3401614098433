import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { toast, cssTransition } from 'react-toastify';
import { toastActions } from '../store';
import { ToastCloseButton } from '../components/atoms';

const transition = cssTransition({
    enter: 'toastEaseIn',
    exit: 'toastEaseOut',
});

const toastConfig = (toastClass: string) => ({
    position: toast.POSITION.BOTTOM_CENTER,
    className: `bjb-toast ${toastClass}`,
    closeButton: ToastCloseButton,
    transition,
});

export function showSuccessToast(action: any) {
    toast.success(action.payload, toastConfig('bjb-toast--success'));
}

export function showErrorToast(action: any) {
    toast.error(action.payload, toastConfig('bjb-toast--error'));
}

export default function* contentSagas() {
    yield takeEvery(getType(toastActions.showSuccess), showSuccessToast);
    yield takeEvery(getType(toastActions.showError), showErrorToast);
}
