import React from 'react';
import classNames from 'classnames';
import { ICheckboxChangeEvent } from './Checkbox';

interface IProps {
    id: string;
    value: boolean;
    label?: any;
    className?: string;
    disabled?: boolean;
    children?: never;
    onChange: (event: ICheckboxChangeEvent) => void;
}

export default class Switch extends React.PureComponent<IProps> {

    onChange = (event: any) => this.props.onChange({ id: this.props.id, checked: event.target.checked });

    render() {
        const classnames = classNames('form-check form-switch material-switch ', this.props.className, { 'switch--no-label': !this.props.label });
        const id = `switch-${this.props.id}`;
        return <div className={classnames}>
            {/*parent renders content in row-reverse direction to enable the adjacent sibling css selector for pseudo elements; so label is in fact rendered first, then the switch button*/}
            <input className='form-check-input' type='checkbox' role='switch' id={id}
                   checked={this.props.value}
                   onChange={this.onChange}
                   disabled={this.props.disabled}
            />
            <label className='form-check-label' htmlFor={id}>{this.props.label}</label>
        </div>;
    }
}
