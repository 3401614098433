import React from 'react';
import { v4 as uuid } from 'uuid';
import { FormFeedback, FormText, Input, InputGroup, Label } from 'reactstrap';
import classnames from 'classnames';
import { InputType } from 'reactstrap/types/lib/Input';

interface IProps {
    id: string;
    label?: string;
    type?: InputType;
    placeholder?: string;
    textBelow?: any;
    value: string | number;
    required?: boolean;
    disabled?: boolean;
    errorMessage?: string;
    className?: string;
    showValidation?: boolean;
    maxLength?: number;
    children?: never;
    onChange: (value: string | number) => void;
    onEnterPressed?: (value: void) => void;
    autofocus?: boolean;
}

export default class TextInput extends React.PureComponent<IProps> {

    clearValue = () => this.props.onChange('');
    onChange = (event: any) => this.props.onChange(event.target.value);

    onKeyUp = (event: any) => {
        if (event.which === 13 && this.props.onEnterPressed) {
            event.preventDefault();
            this.props.onEnterPressed();
        }
    };

    render() {
        const id = this.props.id || uuid().substring(0, 8);
        const isInvalidEmail = this.props.type === 'email' && this.props.value && !this.props.value.toString().match(/(@)+/);
        const invalid = this.props.showValidation && ((this.props.required && !this.props.value) || isInvalidEmail || !!this.props.errorMessage);
        const error = this.props.errorMessage || (isInvalidEmail ? 'Invalid email' : 'Define this field to continue');

        return (
            <div className={classnames('form-group', this.props.className, { 'form-invalid': invalid })}>
                {this.props.label && <Label for={id}>{this.props.label}</Label>}
                <InputGroup>
                    {this.props.value && !this.props.disabled &&
                    <i className="icon icon-clear-primary input--append" onClick={this.clearValue}/>}
                    <Input type={this.props.type || 'text'}
                           value={this.props.value}
                           name={id} id={id}
                           invalid={invalid}
                           maxLength={this.props.maxLength}
                           placeholder={this.props.placeholder}
                           disabled={this.props.disabled}
                           onChange={this.onChange} onKeyUp={this.onKeyUp}
                           autoFocus={this.props.autofocus}/>
                </InputGroup>
                {this.props.textBelow && <FormText>{this.props.textBelow}</FormText>}
                <FormFeedback invalid='true'>{error}</FormFeedback>
            </div>
        );
    }
}
