import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { LoadingIndicator } from '../components/atoms';
import { IRootState } from '../store/store';
import { Decks, Templates } from '../components/business';
import * as route from '../config/routes';

interface ITab {
    id: string;
    label: string;
    path: string;
}

const tabsConfig: ITab[] = [
    { id: 'templates', label: 'Templates', path: route.dashboardTemplates },
    { id: 'decks', label: 'Decks', path: route.dashboardDecks },
];

interface IProps {
    isLoggedIn: boolean;
}

const Dashboard: React.FC<IProps> = (props: IProps) => {

    const { pathname } = useLocation();

    if (!props.isLoggedIn) {
        return <LoadingIndicator/>;
    }

    const isActiveTab = (path: string) => {
        if (!path) {
            return pathname === route.dashboard;
        }
        return pathname.includes(path);
    }

    const tabs = tabsConfig.map((tab: ITab) => {
        return <li key={tab.id} className='nav-item' id={`navTabAdmin${tab.label}`}>
            <Link to={`${route.dashboard}${tab.path}`}
                  className={classnames('nav-link', { active: isActiveTab(tab.path) })}>{tab.label}</Link>
        </li>;
    });

    return <>
        <ul className='nav nav-tabs'>{tabs}</ul>
        <div className='tab-content'>
            <Routes>
                <Route path={route.dashboardDecks} Component={Decks}/>
                <Route path={route.dashboardTemplates} Component={Templates}/> {/*keep at last position as child path is empty*/}
            </Routes>
        </div>
    </>;
}

const mapStateToProps = (state: IRootState) => ({
    isLoggedIn: !!state.user.username,
});

export default connect(mapStateToProps)(Dashboard);
