import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { saveAsPdf } from '../../utils/fileUtils';
import { ITemplate, ITemplateFile } from '../../interfaces/business';
import { IRootState } from '../../store/store';
import { SortableFileTable } from '../atoms';
import templateApi from '../../api/template-api';
import TemplateImporter from './TemplateImporter';
import SortableTemplateTable from '../atoms/SortableTemplateTable';
import { templateActions, toastActions } from '../../store';

interface IProps {
    templateFiles: ITemplateFile[];
    templates: ITemplate[];
    updateTemplateData: () => void;
    showSuccessToast: (text: string) => void;
    showErrorToast: (text: string) => void;
}

export const Templates: React.FC<IProps> = (props: IProps) => {
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const onDownloadFile = (file: ITemplateFile) => templateApi.getTemplateFileById(file.id).then((data: any) => saveAsPdf(data, file.filename));
    const onUpdateTemplate = (template: ITemplate, countries: string) =>
        templateApi
            .updateTemplate(template, countries)
            .then(() => {
                props.updateTemplateData();
                props.showSuccessToast('Successfully updated template.');
            })
            .catch(() => props.showErrorToast('Update failed. Please retry later.'))
            .finally(() => setIsUpdateLoading(false));

    return (
        <Container>
            <Row>
                <Col sm="12">
                    <h2 className="admin-header pt-5 pb-2">Upload and view templates</h2>
                </Col>
            </Row>
            <TemplateImporter />
            <Row>
                <Col sm={12}>
                    <SortableFileTable files={props.templateFiles} onFileClick={onDownloadFile} />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <SortableTemplateTable
                        templates={props.templates}
                        onCountriesChange={onUpdateTemplate}
                        isUpdateLoading={isUpdateLoading}
                        setIsUpdateLoading={setIsUpdateLoading}
                    />
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state: IRootState) => ({
    templateFiles: state.template.templateFiles,
    templates: state.template.templates,
});

const actions = {
    updateTemplateData: templateActions.loadTemplates.request,
    showSuccessToast: toastActions.showSuccess,
    showErrorToast: toastActions.showError,
};

export default connect(
    mapStateToProps,
    actions,
)(Templates);
