import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Location } from 'history';
import * as route from '../../config/routes';
import logo from '../../../styles/assets/images/jb.gif';
import { IRootState } from '../../store/store';
import { userSelectors } from '../../store';

interface IProps {
    location: Location;
    isLoggedIn: boolean;
    children?: never;
}

class JbHeader extends React.PureComponent<IProps> {

    render() {
        const dashboardButtonHidden = this.props.location.pathname.includes(route.dashboard);

        return (
            <header className='toolbar jb-toolbar'>
                <img src={logo} className='logo img-fluid' alt='Julius Baer'/>
                <div className=' jb-toolbar__content'>
                {this.props.isLoggedIn &&
                <Link className={classNames({ 'a': true, 'invisible ': dashboardButtonHidden })}
                      to={route.dashboard} id='btnGotoDashboard'>
                    <i className='icon icon-home-b-primary icon--left icon-inline'/>
                    <span className='d-none d-sm-inline'>Back to dashboard</span></Link>
                }
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state: IRootState) => ({
    location: state.router.location,
    isLoggedIn: userSelectors.isLoggedIn(state),
});


export default connect(
    mapStateToProps,
    null,
)(JbHeader);
