import axios, { AxiosInstance } from 'axios';
import { CON_BACKEND_URL } from '../config/constants';
import { IDeck } from '../interfaces/business';

export class DeckApi {
    readonly axios: AxiosInstance;
    readonly basePath: string;

    constructor(api: AxiosInstance, url: string) {
        this.axios = api;
        this.basePath = `${url}/deck`;
    }

    async getDeckList() {
        return this.axios.get(this.basePath).then(response => response.data);
    }

    async getDeck(deckId: number) {
        return this.axios.get(`${this.basePath}/${deckId}`).then(response => response.data);
    }

    async saveDeck(deck: IDeck) {
        if (deck.id) {
            return this.axios.post(`${this.basePath}/${deck.id}`, deck).then(response => response.data);
        } else {
            return this.axios.post(this.basePath, deck).then(response => response.data);
        }
    }
}

const instance = new DeckApi(axios, CON_BACKEND_URL);
export default instance;
