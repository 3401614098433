import React from 'react';

export const TemplateLegend: React.FC = () => {
    return (
        <>
            <p className="small mb-0 mt-2">Legend:</p>
            <p className="small mb-0">
                <span className="td-found px-1 my-1">&nbsp;</span> - Template is available for required RM locations in required booking centers.
            </p>
            <p className="small mb-0">
                <span className="td-partial px-1 my-1">&nbsp;</span> - Template in a non-default language is missing for some required BC and RM location.
            </p>
            <p className="small mb-0">
                <span className="td-missing px-1 my-1">&nbsp;</span> - Template in the default language is missing for some required BC and RM location.
            </p>
            <p className="small mb-0">"2 / 4" - Template is available for two BC + RM location combinations out of four possible.</p>
            <p className="small">"4" - Template is available in four booking centers.</p>
        </>
    );
};

export default TemplateLegend;
