import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap';
import { SmallContainer } from '../components/utils';
import * as route from '../config/routes';
import { store } from '../store/store';
import { routingActions } from '../store/index';

const ErrorScreen: React.FC = () => {

    const browserBackHandler = () => store.dispatch(routingActions.goTo(route.dashboard));
    React.useEffect(() => {
        window.addEventListener('popstate', browserBackHandler);

        return () => {
            setTimeout(() => window.removeEventListener('popstate', browserBackHandler), 500);
        };
    }, []);

    return <Container>
        <SmallContainer>
            <Row>
                <Col sm={12} className='confirmation'>
                    <i className='icon icon-error icon--big'/>
                    <h2>Oops!</h2>
                    <p className='mb-3'>Something went wrong, try later.</p>
                    <Link to={route.dashboard} className='btn btn-primary' id='btnGoToDashboard'>Back to
                        dashboard</Link>
                </Col>
            </Row>
        </SmallContainer>
    </Container>;

}
export default ErrorScreen;
