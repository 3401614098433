import { AxiosResponse } from 'axios';

export const saveAsFile = (data: any, filename: string, applicationType: string) => {
    const blob = new Blob([data], { type: applicationType });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const a: any = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        const href = URL.createObjectURL(blob);
        a.href = href;
        a.download = filename;
        a.target = '_blank';
        a.click();
        window.URL.revokeObjectURL(href);
    }
};

export const saveAsPdf = (data: any, filename: string) => saveAsFile(data, filename, 'application/pdf');

export const saveFileFromResponse =(response: AxiosResponse) => {
    const filename: string = response.headers['content-disposition'].split('filename=')[1];
    const applicationType: string = response.headers['content-type'];
    saveAsFile(response.data, filename, applicationType);
    return true;
};
