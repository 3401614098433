import React from 'react';
import { Table } from 'reactstrap';
import { IMap, ITemplate, ITemplateFile } from '../../interfaces/business';
import { getUniqueByProp } from '../../utils/sortHelper';
import { Tooltip } from '../atoms';
import { getTemplateConsistencyText } from '../../utils/templateFileUtils';

const getCellValue = (files: ITemplateFile[], requiredBCs: string[], requiredRMLs: string[]) => {
    if (requiredBCs.length && requiredRMLs.length) {
        const coveredCombinations: string[] = [];
        requiredBCs.forEach((bc) =>
            requiredRMLs.forEach((rml) => {
                if (files.find((file) => file.rmLocation === rml && file.bookingCenter === bc)) {
                    coveredCombinations.push(`bc ${bc} + rml ${rml}`);
                }
            }),
        );
        return `${coveredCombinations.length} / ${requiredBCs.length * requiredRMLs.length}`;
    }
    return getUniqueByProp(files, 'bookingCenter').length;
};

const getTooltip = (files: ITemplateFile[], requiredBCs: string[], requiredRMLs: string[]) => {
    if (requiredBCs.length && requiredRMLs.length) {
        const coveredCombinations: string[] = [];
        const uncoveredCombinations: string[] = [];
        requiredBCs.forEach((bc) =>
            requiredRMLs.forEach((rml) => {
                if (files.find((file) => file.rmLocation === rml && file.bookingCenter === bc)) {
                    coveredCombinations.push(`bc ${bc} + rml ${rml}`);
                } else {
                    uncoveredCombinations.push(`bc ${bc} + rml ${rml}`);
                }
            }),
        );
        const coveredCombinationText = coveredCombinations.length ? `Found templates for combinations: ${coveredCombinations.join(', ')}.` : '';
        const uncoveredCombinationText = uncoveredCombinations.length ? `Missing templates for combinations: ${uncoveredCombinations.join(', ')}.` : '';
        return [coveredCombinationText, uncoveredCombinationText].filter((text) => !!text).join('  \n') || '(empty)';
    } else {
        const availableBCs: string[] = getUniqueByProp(files, 'bookingCenter').map((file) => file.bookingCenter);
        const coveredBcText = availableBCs.length ? `Available for booking center: ${availableBCs.join(', ')}.` : '';
        if (requiredBCs.length) {
            const uncoveredBCs = requiredBCs.filter((bc) => !availableBCs.includes(bc));
            const uncoveredBcText = uncoveredBCs.length ? `Missing for booking center: ${uncoveredBCs.join(', ')}.` : '';
            return [coveredBcText, uncoveredBcText].filter((text) => !!text).join('  \n') || '(empty)';
        }
        return coveredBcText || '(empty)';
    }
};

const getCellClassname = (files: ITemplateFile[], language: string, requiredBCs: string[], requiredRMLs: string[], defaultLanguage: string) => {
    if (!defaultLanguage || !requiredBCs.length) {
        return '';
    }
    const bcRmlMap: IMap<ITemplateFile> = {};
    files.forEach((file) => {
        bcRmlMap[`${file.bookingCenter}-${file.rmLocation}`] = file;
    });
    const invalidClassname = language === defaultLanguage ? 'td-missing' : 'td-partial';
    const requiredSpecsCovered = requiredRMLs.every((rml) => requiredBCs.every((bc) => !!bcRmlMap[`${bc}-${rml}`]));
    return requiredSpecsCovered ? 'td-found' : invalidClassname;
};

interface IProps {
    templates: ITemplate[];
    defaultLanguage: string;
    requiredBCs: string[];
    requiredRMLs: string[];
    children?: never;
    isAdditionalTemplates?: boolean;
}

export const TemplateCoverage: React.FC<IProps> = (props: IProps) => {
    if (!props.templates || !props.templates.length) {
        return null;
    }
    const templateFiles = props.templates.map((template) => template.templateFiles).flat();
    const languages: string[] = [...new Set(templateFiles.map((templateFile) => templateFile.language))].sort();
    return (
        <>
            <Table className="table--template-overview">
                <thead>
                    <tr>
                        <th>{props.isAdditionalTemplates ? 'Situational templates' : 'BCs and RM locations per template/language'}</th>
                        {languages.map((language) => (
                            <th style={{ width: '100px' }} key={language}>
                                {language.toLowerCase()}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.templates.map((template) => (
                        <tr key={template.templateNumber}>
                            <th>{template.templateNumber}</th>
                            {languages.map((language) => {
                                const tooltipId = `tooltip-${language}${template.templateNumber}`;
                                const cellFiles = template.templateFiles.filter((file) => file.language === language);
                                const tdClassName = props.isAdditionalTemplates
                                    ? ''
                                    : getCellClassname(cellFiles, language, props.requiredBCs, props.requiredRMLs, props.defaultLanguage);
                                const cellValue = props.isAdditionalTemplates
                                    ? getUniqueByProp(cellFiles, 'bookingCenter').length
                                    : getCellValue(cellFiles, props.requiredBCs, props.requiredRMLs);
                                return (
                                    <td key={`${language}-${template.templateNumber}`} className={tdClassName}>
                                        <span id={tooltipId}>{cellValue}</span>
                                        <Tooltip forId={tooltipId} text={getTooltip(cellFiles, props.requiredBCs, props.requiredRMLs)} />
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </Table>
            {props.defaultLanguage && !props.isAdditionalTemplates
                ? getTemplateConsistencyText(props.templates, props.requiredBCs, props.requiredRMLs, props.defaultLanguage)
                : null}
        </>
    );
};

export default TemplateCoverage;
