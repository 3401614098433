import React from 'react';
import classnames from 'classnames';

interface IProps {
    small?: boolean,
    bottomPadding?: boolean,
    children?: never;
}

export const LoadingIndicatorElement: React.FC<IProps> = (props: IProps) =>
    <div className='jb-spinner-wrapper'>
        <div className={classnames('jb-spinner', props.small ? 'jb-spinner--small' : '')}/>
    </div>;

const LoadingIndicator: React.FC<IProps> = (props: IProps) =>
    <div className={classnames('jb-spinner-container', props.bottomPadding? 'jb-spinner-container--bottom-padded': '')}>
    <LoadingIndicatorElement small={props.small}/>
</div>;

export default LoadingIndicator;
