import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FileInput } from '../atoms';
import templateApi from '../../api/template-api';
import { templateActions, toastActions } from '../../store';
import { IMap, IRejectedFile } from '../../interfaces/business';

const MAX_FILES = 20; // performance/memory restriction
const CONTRACT_TEMPLATE_NAME_REGEX = /^[A-Z]{2}[_-]{1}[a-zA-Z0-9]+[_-]{1}[A-Z]{2}([_-]+[A-Z]{2})?([_-]+.*)?$/;

interface IProps {
    showSuccessToast: (text: string) => void;
    showErrorToast: (text: string) => void;
    updateTemplateData: (params: void) => void;
    className?: string;
}

export const TemplateImporter: React.FC<IProps> = (props: IProps) => {

    const [files, setFiles] = useState<File[]>([]);
    const [ignoredFiles, setIgnoredFiles] = useState<File[]>([]);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [rejectedFileMap, setRejectedFileMap] = useState<IMap<IRejectedFile>>({});

    const onFileSelect = (selectedFiles: FileList) => {
        const updatedFiles = [...files];
        const ignoredFilesCopy: File[] = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            const newFile: File = selectedFiles.item(i);
            const cleanFilename = newFile.name.toUpperCase().replace(/[-]/g, '_');
            if (cleanFilename.match(CONTRACT_TEMPLATE_NAME_REGEX)) {
                const alreadySelectedFileIndex = files.findIndex(oldFile => oldFile.name === newFile.name);
                if (alreadySelectedFileIndex > -1) {
                    updatedFiles[alreadySelectedFileIndex] = newFile;
                } else {
                    updatedFiles.push(newFile);
                }
            } else {
                ignoredFilesCopy.push(newFile);
            }
        }
        setFiles(updatedFiles);
        setIgnoredFiles(ignoredFilesCopy);
    };

    const removeFileAtPosition = (position: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(position, 1);
        setFiles(updatedFiles);
    };

    const resetState = () => {
        setFiles([]);
        setIgnoredFiles([]);
        setUploadInProgress(false);
        setRejectedFileMap({});
    }

    const getFilesJsx = () => files.map((file, index) => {
        const removeFile = () => removeFileAtPosition(index);
        const errorMessage = rejectedFileMap[file.name] && rejectedFileMap[file.name].message;
        return <Card className='card--file' key={`${index}-${file.name}`}>
            <CardBody><p>{file.name}</p>
                <Button color='icon' onClick={removeFile}><i className='icon icon-close-primary'/></Button>
            </CardBody>
            {errorMessage && <p className='error-message'>{errorMessage}</p>}
        </Card>
    });

    const uploadFiles = () => {
        if (uploadInProgress) {
            return;
        }
        setUploadInProgress(true);
        templateApi.uploadTemplateFiles(files).then(() => {
            resetState();
            props.showSuccessToast('Successfully uploaded files.');
            props.updateTemplateData();
        }).catch(error => onErrorUpload(error.response.data.argumentsRejected))
            .finally(() => setUploadInProgress(false))
    };

    const onErrorUpload = (rejectedFiles: IRejectedFile[]) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            const freshRejectedFileMap: IMap<IRejectedFile> = {};
            rejectedFiles.forEach(rejectedFile => freshRejectedFileMap[rejectedFile.path] = rejectedFile);
            setRejectedFileMap(freshRejectedFileMap);
            props.showErrorToast('Validation failed for some files. Please check the errors.');
        } else {
            props.showErrorToast('Upload failed. Please retry later.');
        }
    };

    return (
        <Row className={props.className}>
            <Col sm={12}>
                <FileInput multiple={true} onChange={onFileSelect} accept='application/pdf'
                           label={`Choose PDF templates (max ${MAX_FILES} files)`}/>
                {ignoredFiles.length > 0 && <>
                    <p className='error-message mt-2'>Some files could not be added to the upload list:</p>
                    <ul>
                        {ignoredFiles.map(file => <li className='error-message'
                                                      key={file.name}>{file.name}</li>)}</ul>
                    <p className='error-message'>File name must match the
                        expected pattern 1-2-3_5.pdf or 1-2-3-4_5.pdf, where<br/>(1) is a 2-character booking center code<br/>(2)
                        is a template number (f.e. 2x11, 00879, etc.)<br/>(3) is a
                        2-character language code<br/>(4) is a 2-character RM location code (optional)<br/>(5) is a document type name (f.e. DMA8, DMA-RISK2, etc.).</p>
                </>}
                {files.length > 0 && <div className='mt-2'>
                    {getFilesJsx()}
                </div>}
                {files.length > 0 && files.length <= MAX_FILES &&
                    <p className='large mt-2'>Selected {files.length} file{files.length > 1 ? 's' : ''}.</p>}
                {files.length > MAX_FILES &&
                    <p className='large mt-2 error-message'>Too many files selected ({files.length}).
                        Only {MAX_FILES} files can be uploaded at once.</p>}
                <div className='btn-row mt-2'>
                    <Button color='primary'
                            disabled={!files.length || files.length > MAX_FILES}
                            onClick={uploadFiles}>
                        Upload
                        {uploadInProgress && <i className='icon icon--right icon-loading-white'/>}
                    </Button>
                    {files.length > 0 &&
                        <Button color='secondary'
                                disabled={uploadInProgress}
                                onClick={resetState}>Clear file selection</Button>}
                </div>
            </Col>
        </Row>
    );
}

const actions = {
    updateTemplateData: templateActions.loadAllData,
    showSuccessToast: toastActions.showSuccess,
    showErrorToast: toastActions.showError,
};

export default connect(
    null,
    actions,
)(TemplateImporter);
