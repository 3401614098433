import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { GreyContainer, SmallContainer } from '../components/utils';

const reloadApp = () => {
    window.location.reload();
};

const TimeoutScreen: React.FC = () =>
    <GreyContainer>
        <SmallContainer>
            <Row>
                <Col sm={12} className='confirmation'>
                    <i className="icon icon-error icon--big"/>
                    <h2>Session timeout</h2>
                    <p className='mb-3'>We will be happy to have you back.</p>
                    <Button color='primary' id='btnReload' onClick={reloadApp}>Open Contract Service</Button>
                </Col>
            </Row>
        </SmallContainer>
    </GreyContainer>;

export default TimeoutScreen;
