const sortByProp = (items: any[], propName: string, desc = false) => {
    if (!items || items.length === 0) {
        return items;
    }
    const multiplier = desc ? -1 : 1;
    items.sort((leftItem, rightItem) => {
        const leftIndex = leftItem[propName];
        const rightIndex = rightItem[propName];
        return leftIndex < rightIndex ? -multiplier : (leftIndex === rightIndex ? 0 : multiplier);
    });
    return items;
};

export default sortByProp;

export const getUniqueByProp: (items: any[], prop: string) => any[] = (items, prop) => {
    const result: any[] = [];
    items.forEach(item => {
        if (!result.find(i => i[prop] === item[prop]))
        {result.push(item)}
    });
    return result;
};