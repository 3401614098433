import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Accordion, TableFilter } from '../atoms';
import { DeleteTemplateFileModal } from '../modals';
import { getLoadingListJsx } from '../../utils/renderUtils';
import { IMap, ITemplateFile } from '../../interfaces/business';
import { ISelectOption } from './Select';

interface IProps {
    files: ITemplateFile[];
    onFileClick: (file: ITemplateFile) => void;
}

const getFileFilterOptions = (files: ITemplateFile[], mapFn: (file: ITemplateFile) => string) =>
    [...new Set((files || []).map(mapFn))].map((v) => ({ value: v, label: v }));

const SortableFileTable: React.FC<IProps> = (props: IProps) => {
    const [availableFilters, setAvailableFilters] = useState<IMap<ISelectOption[]>>({
        templateNumber: [],
        bookingCenter: [],
        language: [],
        rmLocation: [],
    });
    const [activeFilters, setActiveFilters] = useState<IMap<string[]>>({
        templateNumber: [],
        bookingCenter: [],
        language: [],
        rmLocation: [],
    });
    const [selectedFile, setSelectedFile] = useState<ITemplateFile>(undefined);

    useEffect(() => {
        const templateNumber = getFileFilterOptions(props.files, (f) => f.templateNumber);
        const bookingCenter = getFileFilterOptions(props.files, (f) => f.bookingCenter);
        const rmLocation = getFileFilterOptions(props.files, (f) => f.rmLocation);
        const language = getFileFilterOptions(props.files, (f) => f.language);
        setAvailableFilters({ templateNumber, bookingCenter, language, rmLocation });
    }, [props.files]);

    const getVisibleFiles = () => {
        const visibleFiles: ITemplateFile[] = props.files.filter((file) => {
            let matchesFilter = true;
            Object.keys(activeFilters).forEach((filePropName: keyof ITemplateFile) => {
                const propActiveFilters = activeFilters[filePropName];
                if (matchesFilter && propActiveFilters.length > 0) {
                    matchesFilter = matchesFilter && propActiveFilters.includes(file[filePropName] as string);
                }
            });
            return matchesFilter;
        });

        return visibleFiles.map((file, index) => {
            const onDownloadClick = () => props.onFileClick(file);
            const onDeleteClick = () => setSelectedFile(file);
            return (
                <tr key={`${index}_${file.filename}`}>
                    <td>{file.filename}</td>
                    <td>{file.templateNumber}</td>
                    <td>{file.bookingCenter}</td>
                    <td>{file.rmLocation}</td>
                    <td>{file.language}</td>
                    <td>
                        <i className="icon icon-download-primary clickable" onClick={onDownloadClick} />
                    </td>
                    <td>
                        <i className="icon icon-delete clickable" onClick={onDeleteClick} />
                    </td>
                </tr>
            );
        });
    };

    const onCloseModal = () => setSelectedFile(undefined);

    const onFilterValueChange = (filePropName: string, value: string) => {
        const propActiveFilters = activeFilters[filePropName];
        const updatedValues = propActiveFilters.includes(value) ? propActiveFilters.filter((f) => f !== value) : [...propActiveFilters, value];
        setActiveFilters({ ...activeFilters, [filePropName]: updatedValues });
    };

    const getTableFilter = (filePropName: keyof ITemplateFile, label: string) => {
        const onFilterChange = (value: string) => onFilterValueChange(filePropName, value);
        return (
            <TableFilter
                id={`filter-${filePropName}`}
                label={label}
                values={activeFilters[filePropName]}
                options={availableFilters[filePropName]}
                onOptionSelected={onFilterChange}
            />
        );
    };

    const getContentJsx = () => (
        <Accordion title={`${props.files.length} files available`}>
            <Table className="table--sortable">
                <thead>
                    <tr>
                        <th>Template name</th>
                        <th>{getTableFilter('templateNumber', 'Template number')}</th>
                        <th>{getTableFilter('bookingCenter', 'Booking center')}</th>
                        <th>{getTableFilter('rmLocation', 'RM location')}</th>
                        <th>{getTableFilter('language', 'Language')}</th>
                        <th />
                        <th />
                    </tr>
                </thead>
                <tbody>{getVisibleFiles()}</tbody>
            </Table>
            {selectedFile && <DeleteTemplateFileModal isOpen={!!selectedFile} templateFile={selectedFile} onClose={onCloseModal} />}
        </Accordion>
    );

    return getLoadingListJsx(props.files, 'No files available yet.', 'Failed to load files.', getContentJsx);
};

export default SortableFileTable;
