import { User } from 'oidc-client-ts';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { IUser } from '../../interfaces/user';

export const login = createAsyncAction(
    'user/LOGIN',
    'user/login-success',
    'user/login-ERROR',
)<ILoginPayload, void, Error>();


export const authenticateUser = createAsyncAction(
    'user/AUTH',
    'user/auth-success',
    'user/auth-ERROR',
)<void, IUser, string>();

export const detectUser = createAsyncAction(
    'user/DETECT',
    'user/detect-success',
    'user/detect-ERROR',
)<void, IUser, string>();

export const userSignedIn = createStandardAction('user/signed-in')<User>();

export interface ILoginPayload {
    username: string,
    password: string
}
