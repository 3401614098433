import React from 'react';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';

interface IProps {
    title: any;
    expandOnStart?: boolean;
    className?: string;
    children: React.ReactNode;
}

interface IState {
    collapse: boolean;
}

export default class Accordion extends React.PureComponent<IProps, IState> {

    state: IState = { collapse: this.props.expandOnStart || false };

    toggle = () => {
        if (!window.getSelection().toString()) {
            this.setState({ collapse: !this.state.collapse });
        }
    };

    render() {
        const titleClassNames = classNames('accordion__title-text', this.state.collapse ? 'opened' : 'closed');

        return (
            <div className={`accordion ${this.props.className || ''}`}>
                <div className={titleClassNames} onClick={this.toggle}>{this.props.title}</div>
                <Collapse isOpen={this.state.collapse} className='accordion__body'>
                    <div className='accordion__body__content'>
                        {this.props.children}
                    </div>
                </Collapse>
            </div>
        );
    }
}
