import React from 'react';
import { Row, Col } from 'reactstrap';

interface IProps {
    children: React.ReactNode;
}

const SmallContainer: React.FC<IProps> = (props: IProps) => (
    <div className="small-container">
        <Row>
            <Col xl={{ offset: 3, size: 6 }} lg={{ offset: 2, size: 8 }} md={12}>
                {props.children}
            </Col>
        </Row>
    </div>);

export default SmallContainer;
