import { getType } from 'typesafe-actions';
import * as actions from './template-actions';
import { IDeck, ITemplate, ITemplateFile } from '../../interfaces/business';
import sortByProp from '../../utils/sortHelper';

export interface ITemplateState {
    templates: ITemplate[];
    templateFiles: ITemplateFile[];
    decks: IDeck[];
}

export const initialState: ITemplateState = {
    templates: undefined,
    templateFiles: undefined,
    decks: undefined,
};

export default function templateReducer(state: ITemplateState = initialState, action: any): ITemplateState {
    switch (action.type) {
        case getType(actions.loadTemplates.success):
            return { ...state, templates:action.payload };
        case getType(actions.loadTemplates.failure):
            return { ...state, templates: null };
        case getType(actions.loadTemplateFiles.success):
            return { ...state, templateFiles: sortByProp(action.payload, 'updatedAt') };
        case getType(actions.loadTemplateFiles.failure):
            return { ...state, templateFiles: null };
        case getType(actions.loadDecks.success):
            return { ...state, decks: action.payload };
        case getType(actions.loadDecks.failure):
            return { ...state, decks: null };
            default:
            return state;
    }
}
