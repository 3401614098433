import classnames from 'classnames';
import React from 'react';

interface IProps {
    name: string;
    label?: string;
    options: IRadioOption[];
    inline?: boolean;
    currentValue?: any;
    recommendedValue?: any;
    required?: boolean;
    disabled?: boolean;
    showValidation?: boolean;
    className?: string;
    children?: never;
    onChange: (value: any) => void;
}

export interface IRadioOption {
    value: any;
    label: string;
}

export default class RadioGroup extends React.PureComponent<IProps> {

    onChange = (e: any) => this.props.onChange(e.target.value);

    getRadiosJsx = () => this.props.options.map((option: IRadioOption) => {
        const id = `${this.props.name}${option.value}`;
        return <div
            className={classnames('form-check', 'position-relative', {'form-check-inline': this.props.inline})}
            key={`${option.value}`}>
            <input type='radio'
                   name={this.props.name}
                   value={option.value}
                   checked={option.value === this.props.currentValue}
                   id={id}
                   disabled={this.props.disabled}
                   onChange={this.onChange}
                   className='form-check-input'/>
            <label className='form-label' htmlFor={id}>
                {option.label}
            </label>
            {option.value === this.props.recommendedValue && <div className='radio-recommended-label'/>}
        </div>;
    });

    render() {
        const invalid = this.props.showValidation && this.props.required && (this.props.currentValue === undefined || this.props.currentValue === null);

        return <fieldset className={classnames(this.props.className, 'form-group position-relative', { 'form-invalid': invalid })}
                         id={this.props.name} disabled={this.props.disabled}>
            {this.props.label && <label className='form-label form-group-title'>{this.props.label}</label>}
            <div>{this.getRadiosJsx()}</div>
            <div className='invalid-feedback'>Define this field to continue</div>
        </fieldset>;
    }
}
