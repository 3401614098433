import { User } from 'oidc-client-ts';
import moment from 'moment';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as route from '../config/routes';
import { IUser, UserRole } from '../interfaces/user';
import { routingActions, userActions, userSelectors } from '../store';
import { REACT_APP_CON_AUTH_RESOURCE, REACT_APP_CON_AUTH_SESSION_KEY } from '../config/constants';
import { store } from '../store/store';

export function* checkIfUserAlreadySignedIn() {
    const storedAuthValue = sessionStorage.getItem(REACT_APP_CON_AUTH_SESSION_KEY);
    const storedUser: User = JSON.parse(storedAuthValue);
    if (storedUser) {
        yield put(userActions.userSignedIn(storedUser))
    }
}

export function setLogoutTimeout(expiresAt: number) {
    const expirationTime = moment('1970-01-01T00:00:00Z').add(expiresAt, 's');
    const timoutMs  = expirationTime.diff(moment(), 'ms');
    setTimeout(() => {
        store.dispatch(routingActions.goTo(route.timeout));
        sessionStorage.removeItem(REACT_APP_CON_AUTH_SESSION_KEY);
    }, timoutMs);
}

export function* persistUser( action: ReturnType<typeof userActions.userSignedIn>) {
    const authUser: User = action.payload; // TODO user info endpoint in JB not yet delivering u number and roles
    console.log(`Detected and persisted user: ${JSON.stringify(authUser?.profile?.email)}. Navigating to dashboard.`);
    const user: IUser = {
        username: 'any', // TODO retrieve u-number nicely authUser.profile.email.substr(0, 6)
        roles: [UserRole.ADMIN], // TODO retrieve user role from payload
    }
    if (REACT_APP_CON_AUTH_RESOURCE === 'devcloud') {
        setLogoutTimeout(authUser.expires_at); // on localhost, silent renew is not working yet
    }

    yield put(userActions.detectUser.success(user));
    const isAdmin: boolean = yield select(userSelectors.isAdmin);
    if (isAdmin) {
        yield put(routingActions.goTo(route.dashboard));
    } else {
        yield put(routingActions.goTo(route.error));
    }
}

export default function* userSagas() {
    yield takeEvery(userActions.userSignedIn, persistUser);
    yield call(checkIfUserAlreadySignedIn);
}
