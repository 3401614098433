import React from 'react';
import classNames from 'classnames';

interface IProps {
    id: string;
    label: any;
    value: boolean;
    inline?: boolean;
    className?: string;
    disabled?: boolean;
    onChange: (event: ICheckboxChangeEvent) => void;
    children?: never;
}

export interface ICheckboxChangeEvent {
    id: string;
    checked: boolean;
}

export default class Checkbox extends React.PureComponent<IProps> {

    onChange = (event: any) => this.props.onChange({ id: this.props.id, checked: event.target.checked });

    render() {
        return <div
            className={classNames('form-check', this.props.className, this.props.inline ? 'form-check-inline' : '')}>
            <input className="form-check-input jb-checkbox"
                   type="checkbox"
                   aria-label={this.props.label}
                   onChange={this.onChange}
                   checked={this.props.value}
                   disabled={this.props.disabled}
                   id={`checkbox-${this.props.id}`}
                   name={`checkbox-${this.props.id}`}/>
            <label className="form-check-label" htmlFor="flexCheckDisabled">
                {this.props.label}
            </label>
        </div>;
    }
}
