import React, { PropsWithChildren } from 'react';
import { Container } from 'reactstrap';

interface IProps {
    bodyColorClass: string;
    children?: React.ReactNode;
}

const BodyColorContainer: React.FC<IProps> = (props: IProps) => {

    React.useEffect(() => {
        document.body.classList.toggle(props.bodyColorClass, true);

        return () => {
            document.body.classList.remove(props.bodyColorClass);
        };
    }, [props.bodyColorClass]);

    return <Container>{props.children}</Container>
}

export const GreyContainer = (props: PropsWithChildren) => <BodyColorContainer bodyColorClass='body-grey'>{props.children}</BodyColorContainer>
