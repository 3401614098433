import React from 'react';
import classnames from 'classnames';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ISelectOption } from './Select';
import { useClickOutsideListener } from './useClickOutsideListener';
import Checkbox from './Checkbox';

interface IProps {
    id: string;
    label: string;
    options?: ISelectOption[];
    values?: string[];
    disabled?: boolean;
    children?: never;
    onOptionSelected: (value: string) => void;
}

const TableFilter: React.FC<IProps> = (props: IProps) => {
    const wrapperRef = React.useRef(null);
    const { active, toggle } = useClickOutsideListener(wrapperRef);

    const toggleWithDisabledCheck = (e: any) => {
        if (!props.disabled) {
            toggle(e);
        }
    };

    const getItems = () => props.options.map((option: ISelectOption) => {
        const onCheckboxClick = () => props.onOptionSelected(option.value);

        return <DropdownItem id={`${props.id}-menu-item`} active={props.values.includes(option.value)}
                             key={option.value}>
            <Checkbox id={option.value} value={props.values.includes(option.value)} onChange={onCheckboxClick}
                      label={option.label}/>
        </DropdownItem>;
    });

    const toggleVoid: () => void = () => null;

    if (!props.options || !props.options.length) {
        return <span>{props.label}</span>;
    }
    return (
        <div ref={wrapperRef}>
            <Dropdown isOpen={active} className="select-dropdown" toggle={toggleVoid}>
                <DropdownToggle tag="span"
                                onClick={toggleWithDisabledCheck}
                                disabled={props.disabled}
                                data-toggle="dropdown"
                                id={`${props.id}-label`}
                                className={classnames('select-dropdown__toggle', { disabled: props.disabled }, { active: props.values.length > 0 })}
                                aria-expanded={active}>
                    {props.label}
                </DropdownToggle>
                <DropdownMenu end={true} id={`${props.id}-menu`}>
                    {getItems()}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

export default TableFilter;
