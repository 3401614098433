import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createReduxHistoryContext, } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { StateType } from 'typesafe-actions';
import rootSaga from '../sagas/root-saga';

import userReducer from './user/user-reducer';
import templateReducer from './template/template-reducer';

const initialState: any = {};

const sagaMiddleware = createSagaMiddleware();
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const createRootReducer = () => combineReducers({
    router: routerReducer,
    user: userReducer,
    template: templateReducer,
});

const rootReducer = createRootReducer();
const middleware = (getDefaultMiddleware: any) =>
    getDefaultMiddleware().concat(routerMiddleware).concat(sagaMiddleware)

export const buildStore = () => configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware,
});

export const store = buildStore();
export const history = createReduxHistory(store);

sagaMiddleware.run(rootSaga);

export type IRootState = StateType<typeof rootReducer>;

